<template>
  <van-popup :lock-scroll="true" :show="overlayShowFlag"
    @click.stop="show = false" class-name="OverlayStyle">
    <div class="wrapper" @click.stop>
      <div class="block">
        <div style="text-align:right">
          <i class="font_family icon-cha block-close" @click="close"></i>
        </div>
        <div class="block-money">
          ￥{{$utils.setMoneyFormat(info.nopay.money+info.nopay.zhihuMoney) }}
        </div>
        <div class="block-labe">申请提现金额</div>
        <van-button type="primary" class="block-submit" size="small"
          @click="submit">确认提现</van-button>

        <div class="block-tips">
          <!-- 分做单位 -->
          <div v-if="info.nopay.money<5000">低于50元每自然月最多可提现1次</div>
          <div v-else>每自然周最多可提现1次</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getPromotionPlatformFilter } from '@/api/public'
import { Toast } from 'vant'
import { submitWithdrawa } from '@/api/profit.js'
export default {
  async mounted() {
    this.platform = await getPromotionPlatformFilter()
  },

  data() {
    return {
      overlayShowFlag: false,
      info: {}
    }
  },
  methods: {
    showPopup(data) {
      this.info = data
      this.overlayShowFlag = true
    },
    async submit() {
      if (this.info.nopay.money + this.info.nopay.zhihuMoney <= 0) {
        return false
      }
      await submitWithdrawa()
      Toast({
        message: '申请成功',
        duration: 3000
      })
      this.$emit('update')
      this.overlayShowFlag = false
    },
    close() {
      this.overlayShowFlag = false
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 622px;
  border-radius: 18px;
  padding: 0px 18px 20px;
  background-color: #fff;
  .block-submit {
    margin: 35px 29px 20px;
    width: 526px;
    background: #fe774c;
    color: white;
    font-size: 30px;
    border: none;
    letter-spacing: 2px;
    border-radius: 6px;
  }
  .block-money {
    font-size: 52px;
    font-weight: bold;
  }
  .block-labe {
    margin-bottom: 26px;
    font-size: 32px;
  }
  .block-close {
    padding: 20px;
    display: inline-block;
    font-size: 28px;
  }
  .block-tips {
    font-size: 20px;
    color: #999;
  }
}
/deep/ .van-radio__label {
  display: flex;
  align-items: center;
}
.bb {
  overflow: scroll;
  .aa {
    padding: 20px 0px;
  }
}
</style>
