<template>
  <div class="profit">
    <div class="profit-top">
      <van-divider class="title">
        达人总转化{{$utils.setNumFormat(allData.count.userNum)}}人 </van-divider>
      <div class="moneyBoxs">
        <div class="moneyBox">
          <div class="mB-left">
            <div class="mBl-titie">总收益</div>
            <div class="mBl-num text-ellipsis">
              ￥{{$utils.setMoneyFormat(allData.count.money+allData.count.zhihuMoney) }}
            </div>
          </div>
          <div class="mb-right">
            <div class="mB-right-top">
              <div class="mBr-name">达人收益</div>
              <div class="mBr-money">
                ￥{{$utils.setMoneyFormat(allData.count.money)  }}</div>
            </div>
            <div class="mB-right-top">
              <div class="mBr-name">知乎收益</div>
              <div class="mBr-money">
                ￥{{$utils.setMoneyFormat(allData.count.zhihuMoney)  }}</div>
            </div>
          </div>
        </div>
        <div class="moneyBox">
          <div class="mB-left">
            <div class="mBl-titie">已结算收益</div>
            <div class="mBl-num text-ellipsis">
              ￥{{ $utils.setMoneyFormat(allData.pay.money+allData.pay.zhihuMoney) }}
            </div>
          </div>
          <div class="mb-right">
            <div class="mB-right-top">
              <div class="mBr-name">达人收益</div>
              <div class="mBr-money">
                ￥{{$utils.setMoneyFormat(allData.pay.money)  }}</div>
            </div>
            <div class="mB-right-top mB-right-btm">
              <div class="mBr-name">知乎收益</div>
              <div class="mBr-money">
                ￥{{$utils.setMoneyFormat(allData.pay.zhihuMoney)  }}</div>
            </div>
          </div>
        </div>
        <div class="moneyBox">
          <div class="mB-left">
            <div class="mBl-titie">结算中收益</div>
            <div class="mBl-num text-ellipsis">
              ￥{{ $utils.setMoneyFormat(allData.waitpay.money+allData.waitpay.zhihuMoney) }}
            </div>
          </div>
          <div class="mb-right">
            <div class="mB-right-top">
              <div class="mBr-name">达人收益</div>
              <div class="mBr-money">
                ￥{{$utils.setMoneyFormat(allData.waitpay.money)  }}
              </div>
            </div>
            <div class="mB-right-top mB-right-btm">
              <div class="mBr-name">知乎收益</div>
              <div class="mBr-money">
                ￥{{$utils.setMoneyFormat(allData.waitpay.zhihuMoney)  }}
              </div>
            </div>
          </div>
        </div>
        <div class="moneyBox">
          <div class="mB-left">
            <div class="mBl-titie"> 未结算收益</div>
            <div class="mBl-num text-ellipsis">
              ￥{{$utils.setMoneyFormat(allData.nopay.money+allData.nopay.zhihuMoney ) }}
            </div>
            <div class="moneyBox-withdrawal" @click="withdrawal">提现</div>
          </div>
          <div class="mb-right">
            <div class="mB-right-top">
              <div class="mBr-name">达人收益</div>
              <div class="mBr-money">
                ￥{{$utils.setMoneyFormat(allData.nopay.money)  }}</div>
            </div>
            <div class="mB-right-top mB-right-btm">
              <div class="mBr-name">知乎收益</div>
              <div class="mBr-money">
                ￥{{$utils.setMoneyFormat(allData.nopay.zhihuMoney )  }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="profit-bottom">
      <div class="pb-table">
        <van-sticky>
          <!-- 1是达人列表，2是知乎列表 -->
          <div class="profit-bottom-time">
            <span :class="{'activeSpan':activeSpan==1}" @click="switchSpan('1')">达人收益</span>
            <span :class="{'activeSpan':activeSpan==2}" @click="switchSpan('2')">知乎收益</span>
          </div>
          <div class="pb-table-title" v-show="activeSpan=='1'">
            <div class="pb-table-title-td  firstCol">日期</div>
            <div class="pb-table-title-td">转化人数</div>
            <div class="pb-table-title-td">收益</div>
          </div>
          <div class="pb-table-title" v-show="activeSpan=='2'">
            <div class="pb-table-title-td  firstCol">收益信息</div>
            <div class="pb-table-title-td">订单量</div>
            <div class="pb-table-title-td">订单收益</div>
            <div class="pb-table-title-td">结算收益</div>
          </div>
        </van-sticky>
        <van-list v-model:loading="loading" :finished="finished" v-show="list != null && list.length > 0"
          class="pb-table-info" finished-text="我也是有底线的呦~" @load="getProfit">
          <template v-if="activeSpan=='1'">
            <div class="pb-table-info-tr zhihuTr" v-for="item in list" :key="item.id">
              <div class="pb-table-info-tr-td statusCol"
                :style="{backgroundColor:(item.settlementStatus==10?'#FF6363':item.settlementStatus==20?'#79BBFF':'#D6D6D6')}">
                {{ status(item.settlementStatus) }}
              </div>
              <div class="pb-table-info-tr-td pb-table-info-tr-time">{{ item.dataTime }}</div>
              <div class="pb-table-info-tr-td">
                {{ item.settlementUserNum }}
              </div>
              <div class="pb-table-info-tr-td">
                {{ $utils.setMoneyFormat(item.kocIncomeMoney)}}
              </div>
            </div>
          </template>
          <template v-if="activeSpan=='2'">
            <div class="pb-table-info-tr zhihuTr" v-for="item in list" :key="item.id">
              <div v-if="item.confirmStatus==10" class="pb-table-info-tr-td statusCol"
                style="backgroundColor:rgb(187 183 183)">
                未结算
              </div>
              <div v-else class="pb-table-info-tr-td statusCol"
                :style="{backgroundColor:(item.kocSettlementStatus==10?'#FF6363':item.kocSettlementStatus==20?'#79BBFF':'#D6D6D6')}">
                {{ status(item.kocSettlementStatus) }}
              </div>
              <div class="pb-table-info-tr-td pb-table-info-tr-time">
                <div class="text-ellipsis">{{item.kocKeywords}}</div>
                <div>{{ item.dataTime }}</div>
              </div>
              <div class="pb-table-info-tr-td">
                {{ item.kocOrderNum  }}
              </div>
              <div class="pb-table-info-tr-td">
                {{ item.confirmStatus==20?$utils.setMoneyFormat(item.kocOrderMoney):'0.00' }}
              </div>
              <div class="pb-table-info-tr-td">
                {{ item.confirmStatus==20?$utils.setMoneyFormat(item.kocMoney):'0.00' }}
              </div>
            </div>
          </template>
        </van-list>
        <van-empty class="empty" description="加载中..." v-show="loading" />
        <van-empty class="empty" description="暂无收益数据" v-show="!loading&&list != null && list.length == 0" />
      </div>
    </div>
    <TabBar />
  </div>
  <tips ref="tips" @update='getAllData' />
  <jumpBinding ref="jumpBinding" />
  <!-- 日期选择组件。目前用不上 -->
  <!-- <van-calendar v-model:show="show" type="range" @confirm="onConfirm" /> -->
</template>

<script>
import { getUserInfo } from '@/api/information.js'
import TabBar from '@/components/tabBar.vue'
import tips from './component/tips.vue'
import jumpBinding from '@/components/jumpBinding.vue'
import {
  getSummary,
  getProfitDetailed,
  getzhihuProfitDetailed
} from '@/api/profit.js'
export default {
  name: 'profit',
  components: {
    TabBar,
    jumpBinding,
    tips
  },
  data() {
    return {
      allData: {
        count: { money: 0, userNum: 0 },
        pay: { money: 0 },
        nopay: { money: 0 },
        waitpay: { money: 0 }
      },
      show: false,
      loading: false,
      finished: false,
      list: [],
      page: 1,
      userInfo: {},
      // 选中的类型，1达人，2知乎
      activeSpan: '1'
    }
  },
  watch: {
    activeSpan(newVal) {
      this.page = 1
      this.list = []
      this.loading = false
      this.finished = false
      this.getProfit()
    }
  },
  async mounted() {
    this.userInfo = await getUserInfo()
    // 设置默认日期
    let date = new Date()
    this.onConfirm([date, date])
    this.getAllData()
    this.getProfit()
  },
  methods: {
    // 切换列表类型
    switchSpan(type) {
      if (this.loading) { return }
      this.activeSpan = type
    },
    async getAllData() {
      this.allData = await getSummary()
    },
    async getProfit() {
      this.loading = true
      let res = []
      if (this.activeSpan == '1') {
        // 达人收益
        res = await getProfitDetailed({ page: this.page, limit: 20 })
      } else {
        // 知乎收益
        res = await getzhihuProfitDetailed({ page: this.page, limit: 20 })
      }
      this.list.push(...res.list)
      this.loading = false
      if (this.list.length >= res.count) {
        this.finished = true
      } else {
        this.finished = false
        this.page += 1
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    onConfirm(date) {
      const [start, end] = date
      this.show = false
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
    },
    status(val) {
      if (val == 10) {
        return '未提现'
      } else if (val == 20) {
        return '提现中'
      } else if (val == 30) {
        return '已提现'
      } else {
        return '暂无'
      }
    },
    /**
     * 提现规则
     * http://product.lingyun5.com/%E8%BF%90%E8%90%A5%E6%8E%A8%E5%B9%BF%E5%B9%B3%E5%8F%B0/%E6%8E%A8%E5%B9%BF-H5/#id=3ujvda&p=%E6%94%B6%E7%9B%8A%E6%95%B0%E6%8D%AE_1&g=1
     */
    withdrawal() {
      if (this.userInfo.apply_settlement === 0) {
        this.$refs.jumpBinding.showPopup()
      } else {
        this.$refs.tips.showPopup(this.allData)
      }
    }
  }
}
</script>

<style scoped lang="less">
.profit {
  overflow: hidden;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  .profit-top {
    .title {
      font-weight: bold;
      padding: 0px 40px;
      border-color: rgb(58, 58, 58);
    }
    .moneyBoxs {
      .moneyBox {
        height: 134px;
        background: #f8f8f8;
        overflow: hidden;
        padding: 20px 10px;
        margin: 20px 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;

        .mB-left {
          position: relative;
          text-align: center;
          width: 320px;
          .mBl-num {
            color: #fe794e;
            font-size: 32px;
            font-weight: bold;
          }
          .mBl-titie {
            width: 200px;
            margin: 0 auto;
            padding: 10px 0px;
            color: #666666;
            border-radius: 50px;
            background-color: #f8f8f8;
            font-weight: bold;
            font-size: 24px;
          }
          .moneyBox-withdrawal {
            position: absolute;
            right: 20px;
            top: 0px;
            color: #ff784c;
            font-size: 24px;
          }
        }
        .mb-right {
          height: 100%;
          flex: 1;
          border-left: 1px dashed #999;
          display: flex;
          padding-left: 40px;
          font-size: 26px;
          flex-direction: column;
          justify-content: space-around;
          .mB-right-top {
            // padding: 17px 0px 17px 30px;
            flex: 1;
            display: flex;
            align-items: center;
            .mBr-money {
              color: #ff784c;
              padding-left: 14px;
            }
          }
        }
      }
    }
  }
  .profit-bottom {
    flex: 1;
    background: #ffffff;
    .profit-bottom-time {
      background: #fff;
      padding: 30px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin: 0px 10px;
        padding: 0px 10px 6px;
        font-size: 26px;
        font-weight: bold;
      }
      .activeSpan {
        color: #fe794e;
        border-bottom: 1px solid #fe794e;
      }
    }
    .pb-table {
      padding: 0px 20px;
      text-align: center;
      font-size: 30px;
      .pb-table-title {
        padding: 14px 0px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f3f1f1;
        .pb-table-title-td {
          width: 25%;
        }
        .firstCol {
          margin-left: 50px;
          width: 28%;
        }
      }
      .pb-table-info {
        padding-bottom: 100px;
        .pb-table-info-tr {
          position: relative;
          display: flex;
          font-size: 28px;
          height: 128px;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgb(221, 220, 220);
          .pb-table-info-tr-td {
            width: 25%;
            overflow: hidden;
            text-overflow: hidden;
            white-space: nowrap;
            div {
              padding: 2px 0px;
            }
          }
          .pb-table-info-tr-time {
            width: 28%;
          }
          .statusCol {
            position: absolute;
            left: 0px;
            width: 24px;
            font-size: 16px;
            padding: 6px;
            color: white;
            box-sizing: content-box;
            border: none;
            border-radius: 10px;
            white-space: inherit !important;
            overflow: auto;
          }
        }
        .zhihuTr {
          padding-left: 50px;
        }
      }
    }
  }
  .empty {
    margin-bottom: 100px;
    height: 100%;
    /deep/ .van-empty__image {
      width: 200px;
      height: 200px;
    }
  }
}
</style>
