<template>
  <van-popup :lock-scroll="true" :show="overlayShowFlag"
    @click.stop="show = false" class-name="OverlayStyle">
    <div class="wrapper" @click.stop>
      <div class="wrapper-top">
        为保护合作双方的合法权益及合作收付款的安全性，请务必完善
        <span>“线上签约”</span>和<span>“系统信息采集”</span>，
        否则推广产生的收益无法申请提现！
      </div>
      <div class="wrapper-btm">
        <div class="wb-btn btn1" @click="overlayShowFlag=false">暂不处理</div>
        <div class="wb-btn btn2" @click="
        $router.push('/withdrawal')
        ">立即完善</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      overlayShowFlag: false
    }
  },
  methods: {
    showPopup() {
      this.overlayShowFlag = true
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  width: 600px;
  height: 100%;
  padding: 40px;
  border-radius: 26px;
  .wrapper-top {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
    color: #333;
    span {
      color: #fe774c;
    }
  }
  .wrapper-btm {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .wb-btn {
      padding: 16px 54px;
      border-radius: 12px;
      font-size: 34px;
    }
    .btn1 {
      color: #999999;
      border: 1px solid #999999;
    }
    .btn2 {
      background: #fe774c;
      border: 1px solid #fe774c;
      color: white;
    }
  }
}
</style>
